import { useEffect, useState } from 'react';
import './App.css';

const onClick = () => {
    window.location = "https://poven.ie"
}

function App() {
    const [messageId, setMessageID] = useState(0);

    const messages = ["Use the Space Bar and Arrow Keys to Move", 
    "Avoid the frying pans", 
    "Dodge, Duck, Dip, Dive and Dodge", 
    "Survive as long as possible!", 
    "Okay I'll leave you too it then", 
    "Have fun", "bye...", "", "", 
    "GOTCHA I'M BACK", 
    "Look at him all smug over there, just throwing frying pans non stop", 
    "Who the hell is that guy anyway?", "", "\"High score gets free pizza for life\" - Phil",
    "you still going or nah?",
    "you big nerd", "If you're still going I'm actually impressed",
    "I would definitely have gotten bored by now",
    "alright now I'm bored of writing these too",
    "So long, come back soon I guess",
    ]

    useEffect(() => {
        const intervalID = setInterval(() =>  {
            setMessageID(messageId + 1)
        }, 8000);
    
        return () => clearInterval(intervalID);
    }, [messageId]);

  return (
    <div className="App">
      <header className="App-header">

        <button className="home-button" onClick={onClick}>Home</button>
        <p>{messages[messageId]}</p>
      </header>
    </div>
  );
}

export default App;
